.register-get-started1 {
  color: white;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
  align-items: center;
  border-radius: 56px;
  flex-direction: column;
  background-color: #000000;
  margin-top: 15px;
  height: 45px;
}
.captcha {
  width: 100%;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom:4px;
  padding-top: 20px;
}
.register-disclaimer {
  font-style: italic;
  padding-bottom:4px;
  padding-top: 4px;
  font-size: .9rem
}
.register-get-started1:hover {
  color: black;
  background-color: #ffffff;
}
.impressum-banner {
  width: 100%;
  display: flex;
  align-items: left;
  padding-top: var(--dl-space-space-unit);
  padding-left: 100px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-size: cover;
  justify-content: space-between;
  background-position: center;
  font-family: "Inter";
}
.register-label {
  padding: 10px 0;
  display: inline-block;
}
.register-container {
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
}
.register-hero {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0c6041;
}
.register-home-logo1 {
  width: auto;
  height: 21px;
  margin-left: var(--dl-space-space-halfunit);
}
.home-logo4 {
  width: auto;
  height: 21px;
}
.register-menu {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: blue;

}
.register-mobile-navigation {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 150;
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}

.register-home-logo {
  width: 64px;
}

.register-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-size: cover;
  justify-content: space-between;
  background-position: center;
}
.register-text {
  font-size: 0.75rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
  letter-spacing: 2px;
}
.register-text01 {
  text-transform: uppercase;
}
.register-text02 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.register-text03 {
  max-width: var(--dl-size-size-maxwidth);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.register-container1 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
}
.register-textinput {
  font-size: 0.75rem;
  border-color: var(--dl-color-gray-500);
  display: block;
  margin-bottom:6px;
  width: 100%;
  height: 3rem;
  padding-left: 4px;
  padding-right: 4px;
}
.register-select {
  font-size: 0.75rem;
  border-color: var(--dl-color-gray-500);
  margin-bottom:5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: var(--dl-color-gray-700);
  height: 3rem;
  width: 100%;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 10px;
  border-radius: 2px;
  margin-right: 2rem;
}
.register-button {
  color: var(--dl-color-gray-white);
  font-size: 0.75rem;
  transition: 0.3s;
  background-color: var(--dl-color-primary1-blue100);
  margin-bottom:5px;
  height: 3rem;
  width: 100%;
}
.register-button:hover {
  color: var(--dl-color-gray-black);
  background-color: transparent;
}
@media(max-width: 991px) {
  .home-logo1 {
    width: 64px;
  }
  .home-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .register-text03 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .register-hero {
    height: 150px;
    position: relative;
    padding-bottom: 180px;
    background-color: var(--dl-color-template-background);
  }
  .register-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .register-text03 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .register-text03 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .register-hero {
    padding-bottom: 60px;
  }

  .register-home-logo {
    margin-left: 0px;
    width: 200px;
    height: auto;
  }

  .register-banner {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .register-container1 {
    height: 314px;
    align-items: center;
    flex-direction: column;
  }
  .register-button {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
  .styleoption {
    font-size: 0.75rem;
    font-weight: bold;
    background-color: red;
  }
  .home-logo4 {
    margin-left: 0px;
  }
  .home-centered {
    height: 60px;
    width: 360px;
    padding-left: 1px;
    padding-right: 1px;
  }
  .register-text02 {
    font-size: 2.0rem;
    text-align: center;
    margin-bottom: var(--dl-space-space-unit);
    width: 90%;
    text-wrap: pretty;
  }
  .register-text03 {
    padding-bottom: 100px;
  }
  .impressum-banner {
    padding-left: 30px;
  }
  .register-container1 {
    height:700px;
    padding-top: 140px;
    padding-bottom: 200px;
  }
  .register-text02 {
    font-size: 1.75rem;
  } 
  .register-get-started1 {
    color: white;
    width: 100%;
    cursor: pointer;
    transition: 0.3s;
    align-items: center;
    border-radius: 56px;
    flex-direction: column;
    background-color: #000000;
    margin-top: 5px;
    margin-bottom: 80px;
    padding-bottom: 20px;
    height: 45px;
  }
  .captcha {
    width: 100%;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom:8px;
    padding-top: 16px;
  }
}


.impressum-banner h4, h2, p {
  margin-bottom: 25px;
}
.impressum-banner ul {
  list-style: none;
  margin-left: 20px;
}
.impressum-banner a {
  color: blue;
  text-decoration: underline;
}

.impressum-title  {
  text-align: center;
  font-size: 25px;
  margin-bottom: 25px;
}
