.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.home-hero {
  width: 100%;
  height: 1000px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #0c6041;
}
.home-hero-header {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  padding-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  background-color: #0c6041;
}
.home-menu {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-mobile-navigation {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 150;
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-logo {
  width: 64px;
}
.home-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}
.home-close-mobile-menu {
  top: var(--dl-space-space-oneandhalfunits);
  right: var(--dl-space-space-oneandhalfunits);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-icon {
  width: 24px;
  height: 24px;
  display: flex;
}
.home-desktop-navigation {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.home-centered {
  width: 100%;
  display: flex;
  max-width: 1200px;
  box-sizing: initial;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
}
.home-left {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 38px;
  flex-direction: row;
  padding-bottom: 38px;
}
.home-logo1 {
  width: auto;
  height: 21px;
  margin-left: var(--dl-space-space-halfunit);
}
.home-logo4 {
  width: auto;
  height: 21px;
}
.home-links1 {
  display: flex;
  align-items: center;
  margin-left: 33px;
  flex-direction: row;
}
.home-right {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-sign-in {
  margin-right: 20px;
}
.home-get-started {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.home-get-started:hover {
  background-color: #5228F5;
}
.home-text06 {
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
}
.home-burger-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-mobile-menu-button {
  width: 40px;
  height: 40px;
  display: none;
}
.home-container2 {
  display: contents;
}
.home-header {
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.home-text07 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 80px;
}
.home-text08 {
  color: rgb(238, 233, 254);
  font-size: 20px;
  max-width: 600px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.home-get-started1 {
  color: white;
  cursor: pointer;
  display: flex;
  margin-top: 40px;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.home-get-started1:hover {
  color: black;
  background-color: #ffffff;
}
.home-text09 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-dashboard-preview {
  top: 650px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: #055031;
  border-width: 30px;
  border-radius: 100px;
  flex-direction: column;
  background-color: #055031;
}
.home-outline {
  display: flex;
  align-items: center;
  border-color: var(--dl-color-template-secondary);
  border-width: 20px;
  border-radius: 70px;
  flex-direction: column;
  background-color: var(--dl-color-template-secondary);
}
.home-image {
  width: 900px;
  z-index: 10;
  user-select: none;
}
.home-features {
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  padding-top: 400px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: EFF0F2;
}
.home-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text10 {
  font-size: 56px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 72px;
}
.home-text11 {
  font-size: 20px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.home-cards {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: 30px;
  align-items: center;
  flex-direction: column;
}
.home-container3 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-icon2 {
  width: 32px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text12 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text13 {
  line-height: 24px;
}
.home-publish {
  height: initial;
}
.home-icon3 {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text14 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text15 {
  line-height: 24px;
}
.home-container4 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.home-icon4 {
  width: 35px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text16 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.home-text17 {
  line-height: 24px;
}
.home-icon5 {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text21 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text22 {
  line-height: 24px;
}
.home-quote-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 100px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: 100px;
  justify-content: center;
  background-color: #000000;
}
.home-quote {
  width: 100%;
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  justify-content: center;
}
.home-message {
  color: rgb(255, 255, 255);
  font-size: 30px;
  max-width: 800px;
  line-height: 42px;
}
.home-author {
  width: 900px;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: row;
}
.home-avatar {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-quote1 {
  color: var(--dl-color-template-secondary);
  width: 100%;
  max-width: 800px;
  margin-left: var(--dl-space-space-unit);
}
.home-text23 {
  font-style: normal;
  font-weight: 600;
}
.home-statistics {
  width: 100%;
  height: 264px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-template-background);
}
.home-container5 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0%;
}
.home-banners {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
}
.home-banner-manage {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.home-container6 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-left1 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text26 {
  text-align: left;
}
.home-text27 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-get-started2 {
  transition: 0.3s;
  margin-bottom: 0;
}
.home-get-started2:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-background);
}
.home-text30 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-image-container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-cards-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
}
.home-action-bar {
  width: 100%;
  display: flex;
  padding: 120px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-action {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #5228F5;
}
.home-heading {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sevenunits);
  padding-left: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sevenunits);
}
.home-text31 {
  color: var(--dl-color-gray-white);
  text-align: left;
}
.home-text35 {
  color: #EEE9FE;
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-get-started3 {
  margin-bottom: 0px;
}
.home-text36 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-images {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-image1 {
  width: 100%;
  object-fit: cover;
  user-select: none;
}
.home-footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #000000;
}
.home-top {
  width: 100%;
  display: flex;
  max-width: 1200px;
  border-color: #3B3B3B;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-right1 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-category {
  width: 240px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text39 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-links2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text40 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.home-text41 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.home-text42 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.home-text43 {
  color: rgb(196, 196, 196);
  font-size: 14px;
}
.home-bottom {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  user-select: none;
  flex-direction: column;
}
.home-branding {
  width: var(--dl-size-size-large);
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);;
  z-index: 100;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text44 {
  color: #686868;
  font-size: 14px;
  line-height: 30px;
}
.home-text31 {
  color: var(--dl-color-gray-white);
  max-width: 800px;
  text-align: left;
}
.home-text32 {
  color: rgb(238, 233, 254);
  max-width: 800px;
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-get-started3 {
  margin-bottom: 0px;
}
.home-text33 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-action-bar {
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
}
@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-close-mobile-menu {
    align-items: center;
    justify-content: center;
  }
  .home-desktop-navigation {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-centered {
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .home-left {
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-logo1 {
    width: 64px;
  }
  .home-links1 {
    display: none;
  }
  .home-sign-in {
    display: none;
  }
  .home-get-started {
    display: none;
  }
  .home-mobile-menu-button {
    display: flex;
  }
  .home-header {
    width: 100%;
    max-width: 1200px;
  }
  .home-image {
    width: 100%;
  }
  .home-features {
    padding-top: 260px;
  }
  .home-title {
    width: 100%;
    max-width: 1200px;
  }
  .home-text10 {
    font-size: 36px;
    line-height: 40px;
  }
  .home-text11 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .home-cards {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .home-text13 {
    line-height: 21px;
  }
  .home-text15 {
    line-height: 21px;
  }
  .home-text17 {
    line-height: 21px;
  }
  .home-text18 {
    line-height: 21px;
  }
  .home-text21 {
    font-size: 24px;
  }
  .home-text22 {
    line-height: 21px;
  }
  .home-quote-container {
    padding-top: var(--dl-space-space-sevenunits);
    padding-bottom: var(--dl-space-space-sevenunits);
  }
  .home-message {
    width: 100%;
    max-width: 800px;
  }
  .home-author {
    width: 100%;
    max-width: 800px;
  }
  .home-statistics {
    height: 800px;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-container5 {
    height: 100%;
    flex-direction: column;
  }
  .home-banner-manage {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-left1 {
    margin-right: var(--dl-space-space-threeunits);
  }
  .home-cards-image {
    width: 360px;
  }
  .home-action {
    flex-direction: column;
  }
  .home-heading {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text31 {
    font-size: 36px;
    line-height: 36px;
  }
  .home-footer {
    padding: var(--dl-space-space-sixunits);
  }
  .home-top {
    flex-direction: column;
  }
  .home-right1 {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-links2 {
    gap: var(--dl-space-space-unit);
  }
  .home-branding {
    width: var(--dl-size-size-medium);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-text44 {
    font-size: 12px;
    line-height: 25px;
    margin-bottom: 0px;
  }
}
@media(max-width: 767px) {
  .home-hero {
    height: 620px;
    position: relative;
    padding-bottom: 180px;
    background-color: var(--dl-color-template-background);
  }
  .home-desktop-navigation {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-centered {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-left {
    margin-left: var(--dl-space-space-unit);
  }
  .home-right {
    margin-right: var(--dl-space-space-unit);
  }
  .home-sign-in {
    display: none;
  }
  .home-get-started {
    display: none;
  }
  .home-header {
    height: 100%;
    margin-top: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .home-text07 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  .home-text08 {
    color: rgb(238, 233, 254);
    font-size: 16px;
    margin-top: var(--dl-space-space-unit);
    line-height: 24px;
  }
  .home-get-started1 {
    margin-top: var(--dl-space-space-twounits);
  }
  .home-dashboard-preview {
    top: 520px;
    width: 90%;
    border-width: 10px;
    border-radius: 45px;
  }
  .home-outline {
    border-width: 10px;
    border-radius: 35px;
  }
  .home-image {
    width: 100%;
  }
  .home-features {
    padding-top: 350px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text10 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
  .home-container3 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-text12 {
    font-size: 24px;
  }
  .home-text13 {
    font-size: 14px;
  }
  .home-publish {
    margin-left: 0px;
  }
  .home-text14 {
    font-size: 24px;
  }
  .home-text15 {
    font-size: 14px;
  }
  .home-container4 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-analyze {
    margin-right: 0px;
  }
  .home-text16 {
    font-size: 24px;
  }
  .home-text17 {
    font-size: 14px;
  }
  .home-text18 {
    font-size: 14px;
  }
  .home-text22 {
    font-size: 14px;
  }
  .home-quote-container {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-message {
    font-size: 24px;
    line-height: 31px;
  }
  .home-author {
    width: 100%;
  }
  .home-avatar {
    width: 40px;
    height: 40px;
  }
  .home-quote1 {
    font-size: 14px;
    max-width: 800px;
  }
  .home-statistics {
    height: 100%;
  }
  .home-container5 {
    gap: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .home-banner-manage {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container6 {
    flex-direction: column;
  }
  .home-left1 {
    margin-right: 0px;
  }
  .home-text26 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text27 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text30 {
    font-size: 16px;
  }
  .home-image-container {
    justify-content: center;
  }
  .home-cards-image {
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-action-bar {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-action {
    align-items: center;
  }
  .home-heading {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text31 {
    font-size: 28px;
    text-align: left;
  }
  .home-text36 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .home-images {
    justify-content: center;
  }
  .home-image1 {
    width: 100%;
  }
  .home-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text31 {
    color: var(--dl-color-gray-white);
    max-width: 800px;
    text-align: left;
  }
  .home-text32 {
    color: rgb(238, 233, 254);
    max-width: 800px;
    line-height: 24px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-get-started3 {
    margin-bottom: 0px;
  }
  .home-text33 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
}
@media(max-width: 479px) {
  .home-hero {
    padding-bottom: 160px;
    padding-left: var(--dl-space-space-threeunits);
  }

  .home-logo {
    margin-left: 0px;
  }
  .home-logo4 {
    margin-left: 0px;
  }
  .home-icon {
    align-items: center;
    justify-content: center;
  }
  .home-text08 {
    color: #EEE9FE;
  }
  .home-text33 {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
  }
  .home-get-started1 {
    width: 100%;
  }
  .home-dashboard-preview {
    top: 500px;
  }
  .home-features {
    padding-top: 200px;
  }
  .home-cards {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-container3 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container4 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .home-analyze {
    margin-bottom: 0px;
  }
  .home-statistics {
    height: 500px;
  }
  .home-text27 {
    line-height: 24px;
  }
  .home-cards-image {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
  }
  .home-action-bar {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-footer {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-avatar {
    width: 10%;
    height: 10%;
  }
  .home-quote1 {
    color: var(--dl-color-template-secondary);
    width: 100%;
    margin-left: var(--dl-space-space-unit);
  }
  .home-text23 {
    font-style: normal;
    font-weight: 600;
  }
  .home-action-bar {
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-banners {
    padding-top: var(--dl-space-space-halfunit);
  }
}
