.active-users-active-users {
  position: relative;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  width:100%
}
.active-users-text {
  color: rgb(255, 255, 255);
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
}
.active-users-text1 {
  color: rgb(255, 255, 255);
  font-size: 15px;
  margin-top: 13px;
  width:100%;
  text-align: center;
}
@media(max-width: 767px) {
  .active-users-text {
    font-size: 28px;
  }
  .active-users-text1 {
    font-size: 14px;
  }
}
@media(max-width: 479px) {
  .active-users-text {
    font-size: 28px;
  }
  .active-users-text1 {
    color: rgb(255, 255, 255);
    font-size: 14px;
  }
}
